import React from 'react';

class Die extends React.Component {
  render() {
    // The faces of the die.
    let faces = [];

    // Loop over each face pushing it on the array.
    this.props.faces.forEach((face, i) => {
      faces.push(
        <li key={i} className="die-item" data-side={i+1}>
          <div className="face">
            <div className={"icon icon-top "+this.props.type}></div>
            <div className={"icon icon-etch icon-top "+this.props.type}></div>
            {face}
            <div className={"icon icon-bottom "+this.props.type}></div>
            <div className={"icon icon-etch icon-bottom "+this.props.type}></div>
          </div>
        </li>);
    });

    return (
      <ol className={"die-list position"+this.props.position+" "+this.props.evenOdd} data-roll={this.props.currentFace} onClick={() => this.props.onClick()}>
        {faces}
      </ol>
    );
  }
}

export default Die;
