import React from 'react';
import Die from './Die.js';
import shuffle from 'shuffle-array';

class DiceSet extends React.Component {

    constructor(props) {
        super(props);
        
        let dice = [];
        
        props.set.dice.forEach((die, i) => {
            dice.push({
                faces: die,
                evenOdd: i%2 === 0 ? 'even-roll' : 'odd-roll',
                position: i,
                currentFace: null,
            });
        });
        dice = this.rollDice(dice);
        this.state = {
            dice: dice
        };
    }

    rollDie(die) {
        die.currentFace = Math.floor(Math.random()*6)+1;
        die.evenOdd = die.evenOdd === 'even-roll' ? 'odd-roll' : 'even-roll';
        return die;
    }

    rollDice(dice) {
        dice = this.shuffleDice(dice);
        let rolledDice = [];
        dice.forEach((die, i) => { rolledDice.push(this.rollDie(die)) });
        return rolledDice;
    }

    updateDice(dice) {
        this.setState({
            dice: this.rollDice(dice)
        })
    }

    updateDie(dieNumber) {
        let dice = this.state.dice;
        dice[dieNumber] = this.rollDie(dice[dieNumber]);
        this.setState({dice: dice});
    }

    shuffleDice(dice) {
        console.log("SHUFFLE DICE!");
        // Initialize the array where we will store positions that have already had a die shuffled into them.
        let takenPositions = [];

        // Loop over each die in the set.
        for(var currentDie = 0; currentDie < dice.length; currentDie++) {
            // Initialize the array where we will store available positions to shift the current die into.
            let freePositions = [];
            // Loop over each potential position.
            for (var currentPotentialPosition = 0; currentPotentialPosition < dice.length; currentPotentialPosition++) {
                // A die can not be shifted into a position that it is in or a position already filled by another die.
                if (dice[currentDie].position !== currentPotentialPosition) {
                    // Assume position is free
                    let positionIsFree = true;
                    // Check if the position is taken by another die.
                    for (var currentTakenPosition = 0; currentTakenPosition < takenPositions.length; currentTakenPosition++) {
                        if (takenPositions[currentTakenPosition] === currentPotentialPosition) {
                            positionIsFree = false;
                        }
                    }
                    // If the position is free push it into the array of available positions.
                    if (positionIsFree) freePositions.push(currentPotentialPosition);
                }
                
            }
            // Shuffle the array of available positions and get the first one.
            console.log("Free Positions: ", freePositions)
            if (freePositions.length > 0) {
                let shuffledPosition = shuffle(freePositions)[0];
                console.log("Shuffled Position:", shuffledPosition);
                dice[currentDie].position=shuffledPosition;
                takenPositions.push(shuffledPosition);
            }
            else {
                // The only free position is the position that this die is already in.
                // Swap it with another die.
                let currentDieNewPosition = shuffle(takenPositions)[0];
                let currentDieCurrentPosition = dice[currentDie].position;

                for (var i=0; i<dice.length; i++) {
                    if (dice[i].position === currentDieNewPosition) {
                        dice[i].position = currentDieCurrentPosition;
                    }
                }

                dice[currentDie].position = currentDieNewPosition;
            }
        }

        return dice;
    }

    renderDice(dice) {
        let renderedDice = [];
        dice.forEach((die, i) => { 
            renderedDice.push(
            <Die
                faces={die.faces}
                currentFace={die.currentFace}
                evenOdd={die.evenOdd}
                position={die.position}
                type={this.props.set.name.toLowerCase()}
                key={i}
                onClick={() => this.updateDie(i)}
            ></Die>)
        });
        return renderedDice;
    }

    render() {
        return (
            <div className={"dice "+this.props.set.name.toLowerCase()+"-dice"}>
                {this.renderDice(this.state.dice)}
                <button onClick={() => this.updateDice(this.state.dice)}>Roll {this.props.set.name} Dice</button>
            </div>
        );
      }
}

export default DiceSet;
