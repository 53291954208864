import React from 'react';
import DiceSet from './DiceSet';
import './App.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      diceSets: [
        {
          name: "Human",
          dice: [
            ['Lyn', 'Arin', 'Wil', 'Ana', 'Fin', 'Ben'],
            ['Ezra', 'Fira', 'Rus', 'Sara', 'Rol', 'Ara'],
            ['Brie', 'Lee', 'Tom', 'Ella', 'Jon', 'Bron'],
            ['Dorn', 'Dain', 'Eva', 'May', 'Cas', 'Mir']
          ]
        },
        {
          name: "Elf",
          dice: [
            ['Duil', 'Mir', 'Avil', 'Quil', 'Gwen', 'Wyn'],
            ['Idel', 'Nel', 'Cel', 'Gail', 'Elle', 'Vail'],
            ['Ara', 'Aris', 'Ria', 'Ian', 'Azra', 'Lia'],
            ['Dawn', 'Kai', 'Agon', 'Layn', 'Lor', 'Orin']
          ]
        },
        {
          name: "Dwarf",
          dice: [
            ['Beor', 'Baer', 'Lynn', 'Orin', 'Beth', 'Thor'],
            ['Brun', 'Rik', 'Khar', 'Bren', 'Rak', 'Ras'],
            ['Olga', 'Fina', 'Grim', 'Dwal', 'Dain', 'Etta'],
            ['Ilda', 'Elja', 'Kala', 'Lee', 'Fil', 'Ril']
          ]
        },
        {
          name: "Halfling",
          dice: [
            ['Bo', 'Moe', 'Lowe', 'Bil', 'Sam', 'Kei'],
            ['Jo', 'Doe', 'Poe', 'Anna', 'Dill', 'Bree'],
            ['Nim', 'Dee', 'Tay', 'May', 'Gil', 'Ham'],
            ['Lee', 'Wil', 'Ildi', 'Adie', 'Lili', 'Kim']
          ]
        },
        {
          name: "Goblin",
          dice: [
            ['Bog', 'Gak', 'Gut', 'Dag', 'Zug', 'Lug'],
            ['Har', 'Lok', 'Raz', 'Rusk', 'Maw', 'Rat'],
            ['Urk', 'Snut', 'Rak', 'Flem', 'Rot', 'Ulg'],
            ['Gor', 'Utha', 'Aggi', 'Elga', 'Khor', 'Ugra']
          ]
        },
        {
          name: "Dragon",
          dice: [
            ['Tyr', 'Laz', 'Lore', 'Rath', 'Fyr', 'Onai'],
            ['Axus', 'Ion', 'Alor', 'Olin', 'Rax', 'Rak'],
            ['Saur', 'Agon', 'Naga', 'Ios', 'Amon', 'Vol'],
            ['Ebon', 'Scar', 'Azul', 'Vero', 'Fros', 'Erus']
          ]
        },
      ]
    }
  }

  render() {
    let diceSets = [];

    // loop over dice sets to add each set
    this.state.diceSets.forEach((set, i) => {
        diceSets.push(<DiceSet set={set} key={i}></DiceSet>);
    });
    return (
      <div className="app">
        <header className="app-header">
          <h1>RPG Name Dice</h1>
        </header>
        <aside className="instructions">
          <h2>Instructions</h2>
          <p>RPG Name Dice are used to generate character names in your favorite fantasy RPG.</p>
          <ul>
            <li>Each individual die generates a name. "Wil", "Azra", and "Orin" are all fine names.</li>
            <li>You can pair any two dice together in any order to create a name. Example: "Kala" and "Ras" become "Kalaras."</li>
            <li>If one die ends in a vowel sound, and the next die starts with a vowel sound try using one or the other sound. Example: "Ara" and "Ella" become "Arella."</li>
            <li>Want even more names? Try combining three dice to make a name! Example: "Cel", "Lor", and "Ian" become "Cellorian."</li>
            <li>Spelling and phonetic sounds don't always match up. Feel free to rewrite the results. Example: "Lee" and "Ella" Could make "Leela" or "Liella"</li>
            <li>Mix and match sets for even more options. Combine Halfling and Dwarf to make gnome names, or Dragon and Human to make tiefling names.</li>
          </ul>
        </aside>
        <main>
          {diceSets}
        </main>
      </div>
    );
  }
}

export default App;
